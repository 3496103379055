import {Utils} from '../../Helpers/Scripts/Utils';

(function ($) {

    "use strict";

    var self,
        SEL_SHOW_MORE = ".js-show-more",
        CLASS_SHOW_MORE_WRAPPER = "show-more-wrap",
        CLASS_SHOW_MORE_BUTTON = "show-more-btn",
        CLASS_SHOW_MORE_TEXT = "show-more-text",
        CLASS_EXPANDED = "expanded",
        CLASS_BUSY = "busy",
        CLASS_HIDDEN = "hidden",
        THROTTLING_DURATION = 300,

        $showMore;

    ori.addModule("showMore", {

        // order of processing of parameters - if one is 0, the next one is taken, until a non-zero value is found:
        // 1. percentToCover (percent of screen height to use)
        // 2. heightToPreserve (screen height to preserve for other content, use the rest)
        //  in the above cases, heightToShow becomes the minimum height to be shown (if 0, default is 100 - css height of the "fadeout" gradient box)
        // 3. heightToShow (height in pixels to be shown)
        // 4. linesToShow (number of standard lines to show; this is fallback, therefore default is non-zero)
        options: {
            percentToCover: 0,
            heightToPreserve: 0,
            heightToShow: 0,
            linesToShow: 4,
            lineHeight: 21
        },

        /**
         * Initialization.
         */
        init: function (options) {
            $.extend(self.options, options);
            self.addShowMore();
            self.recalculate();
            ori.device.bindMedia(self.recalculate, self.ns);
            ori.device.bindResize(self.throttledRecalculate, self.ns);
        },

        addShowMore: function () {
            var $fakeEl = $('<span style="line-height:inherit;display:none;">&nbsp;</span>');

            $showMore = $(SEL_SHOW_MORE);
            $showMore.first().append($fakeEl);
            self.options.lineHeight = $fakeEl.height();
            $fakeEl.remove();

            var $showMoreButton = $(
                `<div class="${CLASS_SHOW_MORE_BUTTON}">
                    <span class="${CLASS_SHOW_MORE_TEXT} link" data-text="${self.options.labelLess}">
                        ${self.options.labelMore}
                    </span>
                </div>`);

            $showMore
                .css("height", "")
                .append($showMoreButton)
                .addClass(CLASS_SHOW_MORE_WRAPPER)
                .on(self.eventString("click"), `.${CLASS_SHOW_MORE_BUTTON}`, self.toggle);
        },

        throttledRecalculate: Utils.throttle(() => self.recalculate(), THROTTLING_DURATION),

        recalculate: function () {
            var reducedHeight = 0,
                minHeight = self.options.heightToShow || 100;

            if (self.options.percentToCover) {
                reducedHeight = ori.device.availableHeightPixels * self.options.percentToCover / 100;
                if (reducedHeight < minHeight)
                    reducedHeight = minHeight;
            } else if (self.options.heightToPreserve) {
                reducedHeight = ori.device.availableHeightPixels - self.options.heightToPreserve;
                if (reducedHeight < minHeight)
                    reducedHeight = minHeight;
            } else if (self.options.heightToShow) {
                reducedHeight = minHeight;
            } else {
                reducedHeight = (self.options.linesToShow + 1) * self.options.lineHeight;
            }

            $showMore.height('');   // we need to drop the fixed height for a moment as the contents might have gotten rearranged quite a bit with a new window size,
                                    // and only then can we read the current full content height as a basis for further calculation
            // now we need to calculate each instance separately, if there is more than one in the document
            $showMore.each((i, showMoreElm) => {
                var $instance = $(showMoreElm);
                var fullHeight = $instance.height() + self.options.lineHeight; // adding one line to the height to accommodate the absolute-positioned "show less" button

                $instance.find(`.${CLASS_SHOW_MORE_BUTTON}`).toggleClass(CLASS_HIDDEN, fullHeight <= reducedHeight);
                if ($instance.hasClass(CLASS_EXPANDED)) {
                    $instance.height(fullHeight);
                    $instance.data("height", Math.min(fullHeight, reducedHeight));
                } else {
                    $instance.height(Math.min(fullHeight, reducedHeight));
                    $instance.data("height", fullHeight);
                }
            });
        },

        toggle: function (e) {
            var $instance = $(e.delegateTarget),
                $showMoreText = $instance.find(`.${CLASS_SHOW_MORE_TEXT}`),
                newText = $showMoreText.data("text"),
                newHeight = $instance.data("height"),
                isExpanded = $instance.hasClass(CLASS_EXPANDED);

            if ($instance.hasClass(CLASS_BUSY))
                return;

            if (isExpanded)
                $instance.removeClass(CLASS_EXPANDED);

            $instance
                .addClass(CLASS_BUSY)
                .data("height", $instance.height())
                .animate({ 'height': newHeight }, {
                    complete: function () {
                        $instance
                            .toggleClass(CLASS_EXPANDED, !isExpanded)
                            .removeClass(CLASS_BUSY);
                        $showMoreText
                            .data("text", $showMoreText.html())
                            .html(newText);
                    }
                });
        },

    });

    self = ori.showMore;

})(jQuery);